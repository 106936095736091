
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { Subject } from 'rxjs';

/**
 * The EmptyLayoutComponent is a layout component that displays a blank page.
 * It is used as a base layout for pages that do not require any specific layout.
 */
@Component({
    selector: 'empty-layout',
    templateUrl: './empty.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FuseLoadingBarComponent, RouterOutlet],
})
export class EmptyLayoutComponent implements OnDestroy {

    /**
     * A subject for unsubscribing from all subscriptions.
     */
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor() {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * Unsubscribes from all subscriptions to prevent memory leaks.
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * This method is called when a component is activated.
     * It pushes the page name to the data layer if it exists.
     * 
     * @param event - The event that triggered the activation.
     * @param outlet - The outlet that was activated.
     */
    onActivate(event, outlet) {
        if (window["dataLayer"]) {
            window["dataLayer"].push({ 
                page_name: outlet.activatedRoute.snapshot.data?.pageName
            });
        }
    }
}
