import { FuseNavigationItem } from '@fuse/components/navigation';

/**
 * The horizontal navigation items for the merchant website.
 */
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: '_',
        type: 'basic',
        //icon: 'heroicons_outline:home',
        link: '/home',
        externalLink: false
    },
    /*
    {
        id: 'about',
        title: '_',
        type: 'basic',
        function: (item: FuseNavigationItem) => {
            const aboutUsElement = document.getElementById('about-us');
            aboutUsElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });
        }
    },
    */
    {
        id: 'book',
        title: '_',
        type: 'basic',
        //icon: 'mat_outline:edit_calendar',
        link: '/book',
        externalLink: false
    },
    {
        id: 'products',
        title: '_',
        type: 'basic',
        //icon: 'heroicons_outline:building-storefront',
        link: '/products',
        externalLink: false
    },
    {
        id: 'blogs',
        title: '_',
        type: 'basic',
        //icon: 'heroicons_outline:book-open',
        link: '/blogs',
        externalLink: false
    },
    {
        id: 'contact',
        title: '_',
        type: 'basic',
        //icon: 'heroicons_outline:envelope',
        link: '/contact',
        externalLink: false
    }
];


/**
 * The vertical navigation data for the merchant website.
 */
export const verticalNavigation: FuseNavigationItem[] = [
    {
        id: 'menu-group',
        title: '_',
        subtitle: '_',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'home',
                title: '_',
                type: 'basic',
                //icon: 'heroicons_outline:home',
                link: '/home',
                externalLink: false
            },
            {
                id: 'history',
                title: '_',
                type: 'basic',
                //icon: 'heroicons_outline:clock',
                link: '/account/orders',
                externalLink: false
            },
            /*
            {
                id: 'about',
                title: '_',
                type: 'basic',
                function: (item: FuseNavigationItem) => {
                    const aboutUsElement = document.getElementById('about-us');
                    aboutUsElement.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest"
                    });
                }
            },
            */
            {
                id: 'book',
                title: '_',
                type: 'basic',
                //icon: 'mat_outline:edit_calendar',
                link: '/book',
                externalLink: false
            },
            {
                id: 'products',
                title: '_',
                type: 'basic',
                //icon: 'heroicons_outline:building-storefront',
                link: '/products',
                externalLink: false
            },
            {
                id: 'blogs',
                title: '_',
                type: 'basic',
                //icon: 'heroicons_outline:book-open',
                link: '/blogs',
                externalLink: false
            },
            {
                id: 'contact',
                title: '_',
                type: 'basic',
                //icon: 'heroicons_outline:envelope',
                link: '/contact',
                externalLink: false
            }
        ]
    },
    {
        id: 'menu-category',
        title: 'Tous nos articles',
        subtitle: 'Catégories',
        type: 'group',
        //icon: 'heroicons_outline:home',
        children: []
    }
];
