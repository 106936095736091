
<!-- Button toggle -->
<button
  aria-label="Open and close basket"
  mat-icon-button
  (click)="basketDrawer.toggle()">
  @if (tpl.nProducts > 0) {
    <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
      <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-primary-600 text-primary-50 text-xs font-medium">
        {{tpl.nProducts}}
      </span>
    </span>
  }
  <mat-icon [svgIcon]="'heroicons_outline:shopping-bag'"></mat-icon>
</button>

<fuse-drawer
  class="w-screen min-w-screen min-h-screen md:w-120 md:min-w-120 z-999"
  [fixed]="true"
  [mode]="'over'"
  [name]="'basket'"
  [position]="'right'"
  #basketDrawer>

  <div class="flex flex-col w-full overflow-auto bg-card" *transloco="let t; read: 'common.basket'">

    <!-- Header -->
    <div class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary">
      <mat-icon class="icon-size-7 text-current" [svgIcon]="'heroicons_solid:shopping-bag'"></mat-icon>
      <div class="ml-3 text-2xl font-semibold tracking-tight line-clamp-3">
        {{ t('title') }}
      </div>
      <button
        aria-label="Close basket"
        class="ml-auto"
        mat-icon-button
        (click)="closeDrawer()">
        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
      </button>
    </div>

    <div class="mb-32 pt-12 px-3 flex flex-col gap-8 md:pt-12 md:px-8">

      <app-basket-content (close)="basketDrawer.close()"></app-basket-content>

    </div>

  </div>

</fuse-drawer>