<ng-container *transloco="let t; read: 'common.user'">

  <!-- Button: No user-->
  @if (!user) {
    <a
      aria-label="User actions"
      mat-icon-button
      [routerLink]="['/sign-in']">
      <mat-icon
      [svgIcon]="'heroicons_outline:user'"></mat-icon>
    </a>
  } @else {
    <!-- Button: user connected-->
    <button
      aria-label="User actions"
      mat-icon-button
      [matMenuTriggerFor]="userActions">
      @if (showAvatar && avatar) {
        <img
          class="w-7 h-7 rounded-full"
          [src]="avatar">
      } @else {
        <mat-icon
        [svgIcon]="'heroicons_outline:user'"></mat-icon>
      }
    </button>
  }

  <mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item
      aria-label="Sign in as">
      <span class="flex flex-col leading-none">
        <span>{{ t('signed-in-as') }}</span>
        <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
      </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <a mat-menu-item
      [routerLink]="['/account/personal']">
      <mat-icon [svgIcon]="'heroicons_outline:user'"></mat-icon>
      <span>{{ t('personal-data') }}</span>
    </a>
    <a mat-menu-item
      [routerLink]="['/account/orders']">
      <mat-icon [svgIcon]="'heroicons_outline:clock'"></mat-icon>
      <span>{{ t('order-history') }}</span>
    </a>
    <button mat-menu-item
      aria-label="Cookies preferences"
      (click)="openCookies()">
      <mat-icon svgIcon="mat_outline:privacy_tip"></mat-icon>
      <span>{{ t('btn-cookies-preferences') }}</span>
    </button>

    <mat-divider class="my-2"></mat-divider>
    <button
      aria-label="Sign out"
      mat-menu-item
      (click)="signOut()">
      <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
      <span>{{ t('sign-out') }}</span>
    </button>
  </mat-menu>

</ng-container>
