import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpHeaders, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, Observable, throwError } from 'rxjs';
import { MerchantService } from '@core/merchant/merchant.service';
import { EnvironmentService } from '@core/utils/environment/environment.service';
import { environment } from '@env/environment';
import { TranslocoService } from '@jsverse/transloco';


/**
 * Interceptor that adds the Authorization header to HTTP requests if the access token is not expired.
 * If the access token is expired, the server will return a "401 Unauthorized" response which will be caught
 * by the response interceptor to log the user out from the app.
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> =>
{
    const authService = inject(AuthService);
    const merchantService: MerchantService = inject(MerchantService);
    const translocoService: TranslocoService = inject(TranslocoService);
    const environmentService: EnvironmentService = inject(EnvironmentService);
    const currentLanguage: string = translocoService.getActiveLang();

    // Clone the request object
    let newReq = req.clone();
    let headers: HttpHeaders = newReq.headers;
    
    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if ( authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken) ) {
        headers = headers.set('Authorization', 'Bearer ' + authService.accessToken);
        newReq = newReq.clone({ headers });
    }

    if (newReq.url.startsWith(environmentService.getApiUrl())) {
        const merchantName: string = merchantService.getCurrentMerchantName(true);
        headers = headers.set('X-Qart-origin-client', merchantName ?? 'unknown');
        headers = headers.set('X-Qart-origin-env', environment.name);
        headers = headers.set('X-Qart-lang', currentLanguage);
        newReq = newReq.clone({ headers });
        if (!merchantName) {
            console.log("No merchant name", newReq.url);
        }
    }

    // Response
    return next(newReq).pipe(
        catchError((error) =>
        {
            // Catch "401 Unauthorized" responses
            if ( error instanceof HttpErrorResponse && error.status === 401 )
            {
                // Sign out
                authService.signOut();

                // Reload the app
                location.reload();
            }

            return throwError(error);
        }),
    );
};
