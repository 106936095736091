
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeEnExtra from '@angular/common/locales/extra/en';
import { initializeApp } from 'firebase/app';
import { register } from 'swiper/element/bundle';
import { environment } from '@env/environment';
import { enableProdMode } from '@angular/core';

registerLocaleData(localeFr, localeFrExtra);
registerLocaleData(localeDe, localeDeExtra);
registerLocaleData(localeEn, localeEnExtra);

initializeApp(environment.firebase);

// register Swiper custom elements
register();

console.log('Running in environment:', environment.name);
if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
    .catch(err => console.error(err));
