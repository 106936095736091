
/**
 * An object containing the paths used in the application.
 */
export const paths = {
    home: 'home',
    signedInRedirect: 'signed-in-redirect',
    confirmationRequired: 'confirmation-required',
    emailVerified: 'email-verified',
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
    redirect: 'redirect',
    signIn: 'sign-in',
    signUp: 'sign-up',
    signOut: 'sign-out',
    basket: 'basket',
    account: 'account',
    product: 'product',
    products: 'products',
    checkout: 'checkout',
    policy: 'policy',
    shippingRates: 'shipping-rates',
    contact: 'contact',
    book: 'book',
    blogs: 'blogs',
    blog: 'blog',
    custom: 'personal-pages',
};
