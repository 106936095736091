
import { Route } from '@angular/router';
import { AuthGuard } from '@core/auth/guards/auth.guard';
import { NoAuthGuard } from '@core/auth/guards/noAuth.guard';
import { LanguageResolver } from '@core/resolver/language.resolver';
import { LayoutComponent } from '@layout/layout.component';
import { paths } from './app.paths';
import { InitialResolver, SmartPathResolver } from './app.resolvers';


/**
 * Defines the routes for the application.
 */
export const appRoutes: Route[] = [

    // Redirect empty path to '/home'
    {path: '', pathMatch : 'full', redirectTo: 'home'},

    // Redirect signed in user to the '/home'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'home'},

    // Auth routes for guests and authenticated users
    {
        path: '',
        component  : LayoutComponent,
        resolve    : {
            init: InitialResolver,
            language: LanguageResolver
        },
        children: [
            {
                path: paths.home,
                loadChildren: () => import('@pages/home/home.routes'),
                data: {
                    pageName: 'home'
                }
            },
            {
                path: paths.contact,
                loadChildren: () => import('@pages/contact/contact.routes'),
                data: {
                    pageName: 'contact'
                }
            },
            {
                path: paths.product,
                loadChildren: () => import('@pages/product/product.routes'),
                data: {
                    pageName: 'product'
                }
            },
            {
                path: paths.products,
                loadChildren: () => import('@pages/products/products.routes'),
                data: {
                    pageName: 'products'
                }
            },
            {
                path: `${paths.products}/:uri`,
                loadChildren: () => import('@pages/product/product.routes'),
                data: {
                    pageName: 'product'
                }
            },
            {
                path: paths.policy,
                loadChildren: () => import('@pages/policy/policy.routes'),
                data: {
                    pageName: 'policy'
                }
            },
            {
                path: paths.shippingRates,
                loadChildren: () => import('@pages/shipping-rates/shipping-rates.routes'),
                data: {
                    pageName: 'shipping-rates'
                }
            },
            {
                path: paths.checkout,
                loadChildren: () => import('@pages/checkout/checkout.routes'),
                data: {
                    pageName: 'checkout'
                }
            },
            {
                path: paths.book,
                loadChildren: () => import('@pages/book/book.routes'),
                data: {
                    pageName: 'book'
                }
            },
            {
                path: paths.blog,
                loadChildren: () => import('@pages/blog/blog.routes'),
                data: {
                    pageName: 'blog'
                }
            },
            {
                path: paths.blogs,
                loadChildren: () => import('@pages/blogs/blogs.routes'),
                data: {
                    pageName: 'blogs'
                }
            },
            {
                path: `${paths.blogs}/:blogSlug`,
                loadChildren: () => import('@pages/blog/blog.routes'),
                data: {
                    pageName: 'blog'
                }
            },
            {
                path: `${paths.custom}/:pageIdx`,
                loadChildren: () => import('@pages/custom/custom.routes'),
                data: {
                    pageName: 'custom'
                }
            }
        ]
    },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component  : LayoutComponent,
        resolve    : {
            init: InitialResolver,
            language: LanguageResolver
        },
        children: [
            {
                path: paths.confirmationRequired,
                loadChildren: () => import('@pages/auth/confirmation-required/confirmation-required.routes'),
                data: {
                    pageName: 'auth-confirmation-required'
                }
            },
            {
                path: paths.emailVerified,
                loadChildren: () => import('@pages/auth/email-verified/email-verified.routes'),
                data: {
                    pageName: 'auth-email-verified'
                }
            },
            {
                path: paths.forgotPassword,
                loadChildren: () => import('@pages/auth/forgot-password/forgot-password.routes'),
                data: {
                    pageName: 'auth-forgot-password'
                }
            },
            {
                path: paths.resetPassword,
                loadChildren: () => import('@pages/auth/reset-password/reset-password.routes'),
                data: {
                    pageName: 'auth-reset-password'
                }
            },
            {
                path: paths.redirect,
                loadChildren: () => import('@pages/auth/redirect/redirect.routes'),
                data: {
                    pageName: 'auth-redirect'
                }
            },
            {
                path: paths.signIn,
                loadChildren: () => import('@pages/auth/sign-in/sign-in.routes'),
                data: {
                    pageName: 'auth-sign-in'
                }
            },
            {
                path: paths.signUp,
                loadChildren: () => import('@pages/auth/sign-up/sign-up.routes'),
                data: {
                    pageName: 'auth-sign-up'
                }
            }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        resolve    : {
            init: InitialResolver,
            language: LanguageResolver
        },
        children: [
            {
                path: paths.signOut,
                loadChildren: () => import('@pages/auth/sign-out/sign-out.routes'),
                data: {
                    pageName: 'auth-sign-out'
                }
            },
            {
                path: paths.account,
                loadChildren: () => import('@pages/account/account.routes'),
                data: {
                    pageName: 'account'
                }
            }
        ]
    },

    // 404
    {
        path: '**',
        component  : LayoutComponent,
        resolve: {
            path: SmartPathResolver,
            init: InitialResolver,
            language: LanguageResolver
        },
        loadChildren: () => import('@pages/error/404/notFound.routes')
    }
];
