<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
@if (tpl.isMobile) {
  <fuse-vertical-navigation
    *transloco="let t; read: 'layout.horizontal.modern'"
    class="dark bg-gray-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation?.default"
    [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
      <div class="flex items-center w-full p-4 pl-6">
        <!-- Logo -->
        <div class="flex items-center justify-center">
          <img class="w-10"
            width="40px"
            height="40px"
            src="assets/images/logo/logo_blue_bg_transparent_big.svg"
            alt="Qart logo"
            loading="lazy">
        </div>
        <!-- Components -->
        <div class="flex items-center ml-auto">
          <user [showAvatar]="false"></user>
        </div>
      </div>
      <!-- User -->
      <div class="flex flex-col items-center w-full px-4 pt-4">
        <div class="relative w-16 h-16">
          @if (user?.photoUrl) {
            <img
              class="w-full h-full rounded-full"
              width="64px"
              height="64px"
              [src]="user?.photoUrl"
              alt="User avatar"
              loading="lazy">
          } @else {
            <mat-icon
              class="icon-size-24"
            [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
          }
        </div>
        <!-- User name -->
        <div class="flex flex-col items-center justify-center w-full mt-0">
          <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
            {{user?.name}}
          </div>
          <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
            {{user?.email}}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container fuseVerticalNavigationContentFooter>
      <div class="mb-32"></div>
    </ng-container>
  </fuse-vertical-navigation>
}

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 items-center" *transloco="let t; read: 'layout'">
  <div class="flex flex-col flex-auto w-full max-w-[1536px]">
    <!-- Top banner -->
    @if (tpl.topBannerText != null && tpl.topBannerText != '') {
      <div
        class="w-full flex flex-col py-2 px-4 items-center justify-center bg-primary"
        [ngClass]="{
          'bg-primary text-on-primary': tpl.isBrowser
        }"
        [ngStyle]="{
          'background-color': !tpl.isBrowser && tpl.merchantColors ? tpl.merchantColors.primary600 : '',
          'color': !tpl.isBrowser && tpl.merchantColors ? tpl.merchantColors.textOnPrimary600 : ''
        }"
        [innerHTML]="tpl.topBannerText | safe: 'html'">
      </div>
    }
    <!-- Header -->
    <div class="relative flex flex-row items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 border-b dark:shadow-none bg-card dark:bg-transparent print:hidden">
      <!-- Navigation toggle button -->
      @if (tpl.isBrowser) {
        @if (tpl.isMobile) {
          <ng-container *ngTemplateOutlet="smallScreen"></ng-container>
        } @else {
          <ng-container *ngTemplateOutlet="largeScreen"></ng-container>
        }
      }
    </div>
    <!-- Content -->
    <div class="flex flex-col flex-auto">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
      Otherwise, layout changes won't be registered and the view won't be updated! -->
      @if (true) {
        <router-outlet #outlet="outlet" (activate)="onActivate($event, outlet)"></router-outlet>
      }
    </div>

    <!-- Whatsapp button -->
    @if (tpl.isBrowser && tpl.whatsappNumber && tpl.whatsappNumber !== '') {
      <a
        class="fixed bottom-3 right-4 z-50"
        href="https://web.whatsapp.com/send?l=en&phone={{ tpl.whatsappNumber }}"
        target="_blank"
        rel="noopener noreferrer">
        <img class="w-12"
            width="256px"
            height="213px"
            src="assets/icons/brands/whatsapp.webp"
            alt="Whatsapp"
            loading="lazy"/>
      </a>
    }
    
    <!-- Footer -->
    @defer (on viewport) {
      <footer></footer>
    } @placeholder {
      <div></div>
    }
  </div>

</div>

<ng-template #smallScreen>
  <div class="flex items-center pr-2 mr-auto space-x-0.5 sm:space-x-2">
    <button
      aria-label="Toggle navigation"
      mat-icon-button
      (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
    </button>
    <search [appearance]="'bar'"></search>
  </div>
  <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
    <user></user>
    <languages></languages>
    <app-basket></app-basket>
  </div>
</ng-template>

<ng-template #largeScreen>
  <!-- Logo -->
  @if (tpl.avatar && tpl.avatar !== '') {
    <div
      class="flex items-center mx-2 lg:mr-4">
      <div class="hidden lg:flex">
        <img
          class="w-16"
          width="64px"
          height="64px"
          [src]="tpl.avatar"
          alt="Logo"
          loading="lazy">
      </div>
      <img
        class="flex lg:hidden w-16"
        width="64px"
        height="64px"
        [src]="tpl.avatar"
        alt="Logo"
        loading="lazy">
    </div>
  }
  <fuse-horizontal-navigation
    class="mr-2 flex flex-row w-full items-center justify-start"
    [name]="'mainNavigation'"
    [navigation]="navigation?.horizontal">
  </fuse-horizontal-navigation>
  <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
    <languages></languages>
    <search [appearance]="'bar'"></search>
    <user></user>
    <app-basket></app-basket>
  </div>
</ng-template>



