import { NgTemplateOutlet, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, NativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { LocalStorageService } from '@core/utils/localStorage/localStorage.service';
import { LangDefinition, TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { CookieService } from 'ngx-cookie-service';


/**
 * Component for displaying and changing the language of the application.
 */
@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
    standalone: true,
    imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    NativeDateModule,
    NgTemplateOutlet
],
})
export class LanguagesComponent implements OnInit, OnDestroy {
    /**
     * The available languages for the application.
     */
    availableLangs: LangDefinition[];

    /**
     * The currently active language.
     */
    activeLang: string;

    /**
     * The country ISO codes for the languages for flags.
     */
    flagCodes: any;

    /**
     * The list of language codes and locales for the application.
     */
    private _localeList: { code: string; locale: string }[];

    /**
     * Constructor
     * @param _translocoService - The `TranslocoService` instance.
     * @param _cookieService - The `CookieService` instance.
     * @param _translocoLocaleService - The `TranslocoLocaleService` instance.
     * @param _dateAdapter - The `DateAdapter` instance.
     * @param _localStorageService - The `LocalStorageService` instance.
     */
    constructor(
        private _translocoService: TranslocoService,
        private _cookieService: CookieService,
        private _translocoLocaleService: TranslocoLocaleService,
        private _dateAdapter: DateAdapter<any>,
        private _localStorageService: LocalStorageService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
         * Initializes the component by getting the available languages from Transloco,
         * subscribing to language changes, and setting the country iso codes and locales for each language.
         */
    ngOnInit(): void {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs() as LangDefinition[];

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {

            // Get the active lang
            this.activeLang = activeLang;
        });

        // Set the country iso codes for languages for flags
        this.flagCodes = {
            'en': 'us',
            'fr': 'fr',
            'de': 'de'
        };

        // Set the country iso codes for languages for the locales
        this._localeList = [
            { code: 'en', locale: 'en-US' },
            { code: 'de', locale: 'de-DE' },
            { code: 'fr', locale: 'fr-FR' }
        ];
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * Use this hook to free up any resources that the component is holding onto.
     */
    ngOnDestroy(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sets the active language for the application and updates the locale and user preferences accordingly.
     * @param lang - The language code to set as the active language.
     */
    setActiveLang(lang: string): void {
        // Verify that the language is available
        if (!this.availableLangs.map(l => l.id).includes(lang)) {
            lang = 'fr';
        }

        // Verify that the language is not the same as the current one
        const activeLanguage: string = this._translocoService.getActiveLang();
        if (activeLanguage !== lang) {
            // Set the active lang
            this._translocoService.setActiveLang(lang);
        }

        // Update the locale
        const userLocale: string = this._localeList.find(
            o => o.code === lang
        ).locale;
        this._translocoLocaleService.setLocale(userLocale);
        this._dateAdapter.setLocale(userLocale);

        // Save the user preference
        this._localStorageService.setItem('lang', lang);

        // Save it in a cookie as well so that it can be re-used across subdomains)
        this._cookieService.set('lang', lang, {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            path: '/',
            domain: 'qart.shop',
            secure: true,
            sameSite: 'Lax'
        });

        // Push the language to GTM
        if (window['dataLayer']) {
            window['dataLayer'].push({
                language: lang
            });
        }
    }

    /**
     * A function used to track items in a list by their index or ID.
     * @param index The index of the item in the list.
     * @param item The item being tracked.
     * @returns The ID of the item, or the index if no ID is available.
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

}
