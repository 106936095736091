import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { environment } from '@env/environment';
import { Subject, takeUntil } from 'rxjs';


/**
 * The root component of the application.
 */
@Component({
  selector   : 'app-root',
  templateUrl: './app.component.html',
  styleUrls  : ['./app.component.scss'],
  standalone : true,
  imports    : [
    RouterOutlet
  ],
})
export class AppComponent implements OnInit {

  /**
   * The favicon element of the merchant website.
   */
  private _favIcon: HTMLLinkElement;

  /**
   * The merchant object that contains information about the merchant.
   */
  private _merchant: Merchant | null = null;

  /**
   * A subject that emits when the component is destroyed.
   */
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * AppComponent class represents the root component of the merchant website.
   */
  constructor(
    /**
     * Injects the DOCUMENT token to access the DOM document object.
     */
    @Inject(DOCUMENT) private _document: any,
    /**
     * Injects the platform ID to check if the platform is browser or server.
     */
    @Inject(PLATFORM_ID) private _platformId: any,
    /**
     * MerchantService instance to interact with the merchant API.
     */
    private _merchantService: MerchantService,
  ) {
    // Get the favicon element from the document
    this._favIcon = this._document.querySelector('#merchantIcon');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initializes the component.
   */
  ngOnInit(): void {

    // Push the environment name to the data layer
    if (isPlatformBrowser(this._platformId)) {
      if (window['dataLayer']) {
        window['dataLayer'].push({
          env: environment.name
        });
      }
    }
    

    // Get the merchant from the merchant service
    this._merchantService.merchant$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((merchant: Merchant | null) => {
        this._merchant = merchant;
        this._updateStaticTemplateData();
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Updates the static template data with the merchant information.
   */
  private _updateStaticTemplateData(): void {
    if (!this._merchant) {
      return;
    }
    if (this._merchant.favicon && this._favIcon) {
      this._favIcon.href = `${environment.qart.imagesUrl}/${this._merchant.name}/${this._merchant.favicon.filename}`;
    }
  }

}

if (typeof Worker !== 'undefined') {
  // Create a new
  const worker = new Worker(new URL('./app.worker', import.meta.url));
  worker.onmessage = ({ data }) => {
    // console.log(`Worker thread: ${data}`);
  };
  worker.postMessage('active');
} else {
  // Web Workers are not supported in this environment.
  // You should add a fallback so that your program still executes correctly.
}

