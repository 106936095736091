import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';

/**
 * Checks if the user is authenticated before allowing access to a route.
 * @param route The route being activated.
 * @param state The current router state.
 * @returns An observable that resolves to true if the user is authenticated, or false otherwise.
 */
export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    const redirectUrl = state.url === '/sign-out' ? '/home' : state.url;
    

    // Check the authentication status
    return authService.check()
      .pipe(
        switchMap((authenticated) => {

          // If the user is not authenticated...
          if (!authenticated) {
            // Redirect to the sign-in page with a redirectUrl param
            const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
            const urlTree = router.parseUrl(`sign-in?${redirectURL}`);

            return of(urlTree);
          }

          // Allow the access
          return of(true);
        })
      );
};