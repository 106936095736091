import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { LocalStorageService } from '@core/utils/localStorage/localStorage.service';
import { getBrowserLang, LangDefinition, TranslocoService } from '@jsverse/transloco';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';


/**
 * Resolves the language for the application.
 * 
 * @remarks
 * This resolver sets the active language for the application based on the user's preferences.
 * It first tries to get the language from the query parameters, then from cookies, local storage,
 * and finally from the browser's language settings. If none of these sources provide a language,
 * it gets the default language from the merchant website.
 */
@Injectable({
    providedIn: 'root'
})
export class LanguageResolver implements Resolve<string> {
  /**
   * The available languages for the application.
   */
  private availableLangs: LangDefinition[];

  /**
   * The list of locales for each language.
   */
  private _localeList: { code: string; locale: string }[];

  /**
   * Creates an instance of LanguageResolver.
   * 
   * @param _platformId - The platform ID.
   * @param _document - The document object.
   * @param _merchantService - The merchant service.
   * @param _translocoService - The Transloco service.
   * @param _cookieService - The cookie service.
   * @param _translocoLocaleService - The Transloco locale service.
   * @param _dateAdapter - The date adapter.
   * @param _localStorageService - The local storage service.
   */
  constructor(
    @Inject(PLATFORM_ID) private _platformId: any,
    @Inject(DOCUMENT) private _document: Document,
    private _merchantService: MerchantService,
    private _translocoService: TranslocoService,
    private _cookieService: CookieService,
    private _translocoLocaleService: TranslocoLocaleService,
    private _dateAdapter: DateAdapter<any>,
    private _localStorageService: LocalStorageService
  ) {
    // Get the available languages from transloco
    this.availableLangs =
      this._translocoService.getAvailableLangs() as LangDefinition[];

    this._localeList = [
      { code: 'en', locale: 'en-US' },
      { code: 'de', locale: 'de-DE' },
      { code: 'fr', locale: 'fr-FR' },
    ];
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sets the active language and updates the locale, user preference, HTML attribute, cookie, and GTM.
   * @param lang - The language to set as active.
   * @returns The active language.
   */
  private _setActiveLang(lang: string): string {

    // Verify that the language is available
    if (!this.availableLangs.map(l => l.id).includes(lang)) {
      lang = 'fr';
    }

    // Verify that the language is not the same as the current one
    const activeLanguage: string = this._translocoService.getActiveLang();
    if (activeLanguage !== lang) {
        // Set the active lang
        this._translocoService.setActiveLang(lang);
    }
    
    // Update the locale
    const userLocale: string = this._localeList.find(
      o => o.code === lang
    ).locale;
    this._translocoLocaleService.setLocale(userLocale);
    this._dateAdapter.setLocale(userLocale);

    // Save the user preference
    this._localStorageService.setItem('lang', lang);

    // Update the language HTML attribute
    this._document.documentElement.lang = lang;
    
    // Save it in a cookie as well so that it can be re-used across subdomains)
    this._cookieService.set('lang', lang, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      path: '/',
      domain: 'qart.shop',
      secure: true,
      sameSite: 'Lax'
    });

    // Push the language to GTM
    if (isPlatformBrowser(this._platformId)) {
      if (window['dataLayer']) {
        window['dataLayer'].push({ 
            language: lang
        });
      }
    }
    return lang;
  }

  /**
   * Returns the language from the query parameters of the given route snapshot.
   * @param route - The route snapshot to get the language from.
   * @returns The language string from the query parameters.
   */
  private _getLanguageFromQueryParams(route: ActivatedRouteSnapshot): string {
    const language: string = route.queryParams['lang'];
    return language;
  }

  /**
   * Returns the language value stored in the cookie.
   * @param route - The activated route snapshot.
   * @returns The language value stored in the cookie.
   */
  private _getLanguageFromCookie(route: ActivatedRouteSnapshot): string {
    const language: string = this._cookieService.get('lang');
    return language;
  }

  /**
   * Retrieves the language from local storage.
   * @param route - The activated route snapshot.
   * @returns The language string retrieved from local storage.
   */
  private _getLanguageFromLocalStorage(route: ActivatedRouteSnapshot): string {
    const language: string = this._localStorageService.getItem('lang');
    return language;
  }

  /**
   * Returns the language code of the user's browser.
   * @param route - The activated route snapshot.
   * @returns The language code of the user's browser.
   */
  private _getLanguageFromBrowser(route: ActivatedRouteSnapshot): string {
    const language: string = getBrowserLang();
    return language;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolves the language to be used in the application.
   * Tries to get the language from query params, cookies, local storage, browser, and merchant website.
   * If none of these sources provide a language, defaults to French.
   * @param route The activated route snapshot.
   * @returns An observable that emits the resolved language.
   */
  resolve(route: ActivatedRouteSnapshot): Observable<string> {
    let language: string = 'fr';

    // Try to get the language from the query params
    language = this._getLanguageFromQueryParams(route);
    if (language) {
      language = this._setActiveLang(language);
      return of(language);
    }

    if (isPlatformBrowser(this._platformId)) {
      // Try to get the language from the cookies
      language = this._getLanguageFromCookie(route);
      if (language) {
        language = this._setActiveLang(language);
        return of(language);
      }

      // Try to get the language from the local storage
      language = this._getLanguageFromLocalStorage(route);
      if (language) {
        language = this._setActiveLang(language);
        return of(language);
      }

      // Try to get the language from the browser
      language = this._getLanguageFromBrowser(route);
      if (language) {
        language = this._setActiveLang(language);
        return of(language);
      }
    }

    // Get the language from the merchant website
    this._merchantService.getMerchant()
      .subscribe((merchant: Merchant) => {
        const language = merchant?.website?.defaultLanguage;
        if (language) {
          this._setActiveLang(language);
          return of(language);
        } else {
          this._setActiveLang('fr');
          return of('fr');
        }
    });
    
  }
}
