import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { SettingsService } from '@core/settings/settings.service';
import { Settings } from '@core/settings/settings.types';


/**
 * Service for managing Google Tag Manager integration.
 */
@Injectable({
    providedIn: 'root'
})
export class GoogleTagManagerService {

    /**
     * Flag indicating whether the code is running in a browser environment.
     */
    private _isBrowser: boolean;

    /**
     * The settings of the current merchant.
     */
    private _settings: Settings;

    /**
     * Flag indicating whether Google Tag Manager has been loaded.
     */
    private _gmtLoaded: boolean = false;

    /**
     * Creates an instance of GoogleTagManagerService.
     * @param _platformId - The platform ID.
     * @param _document - The document object.
     * @param _settingsService - The settings service.
     */
    constructor(
        @Inject(PLATFORM_ID) private _platformId: any,
        @Inject(DOCUMENT) private _document: any,
        private _settingsService: SettingsService
    ) {
        this._isBrowser = isPlatformBrowser(this._platformId);
        if (!this._isBrowser) {
            return;
        }
        // Get the settings
        this._settingsService.settings$
            .subscribe((settings: Settings | null) => {
                const reload: boolean = this._settings?._id.toString() !== settings?._id.toString();
                this._settings = settings;
                if (this._settings && !this._gmtLoaded && reload) {
                    this.loadGTM();
                    this._gmtLoaded = true;
                }
            });
    }

    /**
     * Loads Google Tag Manager.
     */
    loadGTM(): void {
        // Retrieve the tracking ID of the Google Tag Manager
        if (!this._settings.googleTagManagerConnected) {
            return;
        }
        const trackingId: string = this._settings.googleTagManagerInfo?.trackingId;
        if (!trackingId || trackingId === '') {
            return;
        }
        if (!window || !window['dataLayer']) {
            console.log('Google Tag Manager dataLayer not found');
            return;
        }
        // Injecting GA main script asynchronously
        /*
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });
        */
        const tag = this._document.getElementsByTagName('script')[0];
        const script = this._document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtm.js?id=' + trackingId;
        tag.parentNode.insertBefore(script, tag);
        
        /**
         * Add the following iframe as the first tag after the opening body tag
         */
        const noscript = this._document.createElement('noscript');
        const iframe = this._document.createElement('iframe');
        iframe.src = 'https://www.googletagmanager.com/ns.html?id=' + trackingId;
        iframe.height = '0';
        iframe.width = '0';
        iframe.style.display = 'none';
        iframe.style.visibility = 'hidden';
        noscript.appendChild(iframe);
        this._document.body.insertBefore(noscript, this._document.body.firstChild);
        
        console.log('Google Tag loaded: ' + trackingId);

    }
}
