<ng-container *transloco="let t; read: 'common.search'">

  <!-- Bar search -->
  @if (appearance === 'bar') {
    @if (!opened) {
      <button
        aria-label="Open search tool"
        mat-icon-button
        (click)="open()">
        <mat-icon [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
      </button>
    } @else {
      <div
        class="absolute inset-0 flex items-center shrink-0 z-99 bg-card"
        @slideInTop
        @slideOutTop>
        <mat-icon
          class="absolute ml-6 sm:ml-8"
        [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
        <input
          class="w-full h-full px-16 sm:px-18"
          [formControl]="searchControl"
          [matAutocomplete]="matAutocomplete"
          placeholder="{{ t('search-placeholder') }}"
          (keydown)="onKeydown($event)"
          (keyup.enter)="seeAllResults()"
          #barSearchInput>
        <mat-autocomplete
          class="max-h-128 sm:px-2 border-t rounded-b shadow-md"
          [disableRipple]="true"
          #matAutocomplete="matAutocomplete">
          @if (resultSets && !resultSets.length) {
            <mat-option
              class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
              >
              {{ t('no-result') }}
            </mat-option>
          }
          @for (resultSet of resultSets; track trackByFn($index, resultSet)) {
            <mat-optgroup class="flex items-center mt-2 px-2">
              <span class="text-sm font-semibold tracking-wider text-secondary">{{resultSet.label.toUpperCase()}}</span>
            </mat-optgroup>
            @for (result of resultSet.results; track trackByFn($index, result)) {
              <mat-option
                class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
                (click)="navigateTo(result.url)">
                <!-- Product -->
                @if (resultSet.id === 'products') {
                  <ng-container *ngTemplateOutlet="productResult; context: {$implicit: result}"></ng-container>
                }
              </mat-option>
            }
            @if (showSeeAllResults) {
              <mat-option
                class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover">
                <a class="flex items-center"
                  [routerLink]="['/products']"
                  [queryParams]="{ 'q': searchControl.value }"
                  queryParamsHandling="merge">
                  <div class="ml-2 truncate text-primary font-semibold">
                    <span>{{ t('see-all-results') }}</span>
                  </div>
                </a>
              </mat-option>
            }
          }
        </mat-autocomplete>
        <button
          aria-label="Close search tool"
          class="absolute top-1/2 right-5 sm:right-7 shrink-0 w-10 h-10 -mt-5"
          mat-icon-button
          (click)="close()">
          <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
      </div>
    }
  }

  <!-- Basic search -->
  @if (appearance === 'basic') {
    <div class="w-full sm:min-w-80">
      <mat-form-field subscriptSizing="dynamic" class="w-full">
        <mat-icon
          matPrefix
        [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
        <input
          matInput
          [formControl]="searchControl"
          [matAutocomplete]="matAutocomplete"
          placeholder="{{ t('search-placeholder') }}"
          (keydown)="onKeydown($event)">
      </mat-form-field>
      <mat-autocomplete
        class="max-h-128 mt-1 rounded"
        [disableRipple]="true"
        #matAutocomplete="matAutocomplete">
        @if (!resultSets || !resultSets.length) {
          <mat-option
            class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
            >
            {{ t('no-result') }}
          </mat-option>
        }
        @for (resultSet of resultSets; track trackByFn($index, resultSet)) {
          <mat-optgroup class="flex items-center mt-2 px-2">
            <span class="text-sm font-semibold tracking-wider text-secondary">{{resultSet.label.toUpperCase()}}</span>
          </mat-optgroup>
          @for (result of resultSet.results; track trackByFn($index, result)) {
            <mat-option
              class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
              (click)="navigateTo(result.url)">
              <!-- Product -->
              @if (resultSet.id === 'products') {
                <ng-container *ngTemplateOutlet="productResult; context: {$implicit: result}"></ng-container>
              }
            </mat-option>
          }
        }
        @if (showSeeAllResults) {
          <mat-option
            class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover">
            <a class="flex items-center"
              [routerLink]="['/products']"
              [queryParams]="{ 'q': searchControl.value }"
              queryParamsHandling="merge">
              <div class="ml-3 truncate text-primary font-semibold">
                <span>{{ t('see-all-results') }}</span>
              </div>
            </a>
          </mat-option>
        }
      </mat-autocomplete>
    </div>
  }

  <!-- Product result template -->
  <ng-template
    #productResult
    let-result>
    <div class="flex items-center">
      <div class="flex shrink-0 items-center justify-center w-8 h-8 rounded-sm overflow-hidden bg-primary-100 dark:bg-primary-800">
        @if (result.img) {
          <img
            [src]="result.img">
        } @else {
          <mat-icon
            class="m-0 icon-size-5 text-primary dark:text-primary-400"
          [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        }
      </div>
      <div class="ml-3 truncate">
        <span [innerHTML]="result.object.name"></span>
      </div>
    </div>
  </ng-template>

</ng-container>