import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EnvironmentService } from '@core/utils/environment/environment.service';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { Observable } from 'rxjs';

/**
 * TranslocoHttpLoader is a class that implements the TranslocoLoader interface to load translations from a JSON file using HttpClient.
 * @implements {TranslocoLoader}
 */
@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader
{
    private _httpClient = inject(HttpClient);
    private _environmentService: EnvironmentService = inject(EnvironmentService);
    private _path: string;

    constructor() {
        this._path = 'assets/i18n';
        /*
        const baseUrl: string = this._environmentService.getBaseUrl();
        if (baseUrl !== '') {
            this._path = `${baseUrl}/assets/i18n`;
        } else {
            this._path = 'assets/i18n';
        }
        */
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get translation
     *
     * @param lang
     */
    getTranslation(lang: string): Observable<Translation>
    {
        return this._httpClient.get<Translation>(`${this._path}/${lang}.json`);
    }
}
